<template>
  <b-card class="py-2 px-2">
    <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      @row-clicked="rowClick($event)">
      <template #cell(no)="{ index }">
        {{ index + 1 }}
      </template>
      <template #cell(exportDate)="{ item }">
        {{ item.exportDate | formatDateTimeEn }}
      </template>
      <template #cell(download)="{ item }">
        <a
          v-if="item.isFinish"
          :href="item.link"
          target="_blank">
          <b-button
            variant="success"
          >
            <feather-icon
              icon="DownloadIcon"
            />
            Download
          </b-button>
        </a>
        <div v-else>
          <b-badge variant="primary">
            Pending
          </b-badge>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ReportProvider from '@/resources/ReportProvider'

const ReportService = new ReportProvider()

export default {
  components: { FeatherIcon },
  data () {
    return {
      fields: [
        { key: 'no', label: 'No' },
        { key: 'name', label: 'Report Name' },
        { key: 'exportDate', label: 'Export Date' },
        { key: 'download', label: '' }
      ],
      items: []
    }
  },
  created () {
    this.getReportDownload()
  },
  methods: {
    async getReportDownload () {
      try {
        const { data } = await ReportService.reportDownload()
        this.items = data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
